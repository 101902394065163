<template>
  <div>
    <h1 class="mb-0">Recuperar Contraseña</h1>
    <p>
      Ingrese su dirección de correo electrónico y le enviaremos un correo electrónico con
      instrucciones para restablecer su contraseña.
    </p>
    <form class="mt-4" @submit.prevent="submitForm">
      <div class="form-group">
        <label>Correo Electrónico</label>
        <input type="email" class="form-control mb-0" v-model="email" />
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">
          Recuperar Contraseña
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { Toast } from '../../../mixins/toast'
import api from './../../../services/api'

export default {
  data: () => ({
    email: null,
    submitted: false,
    errorMessages: []
  }),
  mounted () {
  },
  methods: {
    async submitForm () {
      if (!this.email) {
        Toast.fire({
          icon: 'error',
          title: 'Ingrese Correo Electrónico'
        })
        return // Sale de la función si no se proporciona un correo electrónico
      }

      try {
        await api.post('/forgot-password', {
          email: this.email
        })

        Toast.fire({
          icon: 'success',
          title: 'Correo Electrónico Enviado'
        })
      } catch (error) {
        if (error && error.data && error.data.errors) {
          this.errorMessages = []

          for (let field in error.data.errors) {
            this.errorMessages.push(...error.data.errors[field])
          }

          this.$bvToast.toast(this.errorMessages.join('<br>'), {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        } else {
          this.$bvToast.toast('Ha ocurrido un error', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        }
      }
    }

  }
}
</script>
